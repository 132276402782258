import { FeatureData, ProduitData } from '@innedit/innedit';
import {
  DocumentType,
  PriceType,
  ProduitType,
  ReservationType,
  SlotType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { PageProps } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../../../components/Button';
import Slots from '../../../../../components/Slots';
import ProduitPrice from '../../../../../containers/Produit/Price';
import Reservation from '../../../../../containers/Reservation/Form';
import SignUpOrIn from '../../../../../containers/SignUpOrIn';
import TemplatePublic from '../../../../../templates/public';
import getUser from '../../../../../utils/getUser';
import { UserProps } from '../../../../../utils/requireUser';

dayjs.locale('fr');

const PageEspaceProduitSlotDate: FC<PageProps & Partial<UserProps>> = ({
  location: { href },
  params: { date, espaceId, produitId, slotId },
  user,
}) => {
  const [produit, setProduit] = useState<DocumentType<ProduitType>>();
  const [slot, setSlot] = useState<DocumentType<SlotType>>();
  const [qtyDisponible, setQtyDisponible] = useState<number>();
  const [isReserved, setIsReserved] = useState<boolean>();

  const [price, setPrice] = useState<DocumentType<PriceType>>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    let isMounted = true;
    const produitData = new ProduitData({ espaceId });
    produitData
      .findById(produitId)
      .then(document => isMounted && setProduit(document))
      .catch(e => setError(e.message));

    return () => {
      isMounted = false;
    };
  }, [produitId]);

  useEffect(() => {
    let isMounted = true;

    const slotData = new FeatureData<SlotType>({
      espaceId,
      collectionName: `produits/${produitId}/slots`,
    });

    slotData
      .findById(slotId)
      .then(document => {
        if (isMounted) {
          setSlot(document);
          const reservationData = new FeatureData<ReservationType>({
            espaceId,
            collectionName: 'reservations',
          });

          reservationData
            .find({
              wheres: {
                date,
                produit: produitId,
                slot: slotId,
              },
            })
            .then(documents => {
              if (isMounted) {
                // On regarde si l'utilisateur n'a pas déjà une réservation pour cette date
                if (
                  user &&
                  documents.some(
                    d =>
                      d.produit === produitId &&
                      d.slot === slotId &&
                      d.slotDate === date &&
                      d.user === user.id,
                  )
                ) {
                  // une reservation a déjà été réalisé à cette date
                  setIsReserved(true);
                }
                // On récupère la quantité total utilisée
                setQtyDisponible(
                  (document.quantity || 0) -
                    documents.reduce((acc, d) => acc + (d.quantity || 0), 0),
                );
              }

              return true;
            })
            .catch(toast.error);
        }

        return true;
      })
      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [date, produitId, slotId, user?.id]);

  const handlePriceOnSelect = async (newPrice: DocumentType<PriceType>) => {
    setPrice(newPrice);
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!produit) {
    return <div>Chargement en cours</div>;
  }

  const { name } = produit;

  return (
    <TemplatePublic espaceId={espaceId}>
      <h1 className="text-xl font-bold">{name}</h1>
      {slot ? (
        <Slots datas={[slot]} espaceId={espaceId} produitId={produitId} />
      ) : (
        <div>Chargement en cours du crénau</div>
      )}
      <div>
        <Button
          className="mt-6"
          to={`/${espaceId}/${produitId}/`}
          variant="link"
        >
          Voir tous les crénaux disponibles
        </Button>
      </div>
      <div className="max-w-md mx-auto">
        <p>Vous êtes sur le point de réserver un créneau pour le :</p>
        <span className="text-center font-normal">
          {dayjs(date).format('dddd D MMMM YYYY')}{' '}
          {slot && <span>de {slot.start}</span>}
        </span>

        <div className="bg-light-300 p-6 rounded mt-6">
          <h3 className="font-normal">Tarif</h3>
          <ul>
            <ProduitPrice
              as="li"
              doc={produit}
              onSelect={handlePriceOnSelect}
              selected={price?.id}
              user={user}
            />
          </ul>
        </div>

        {isReserved && (
          <div className="mt-12">
            Ce créneau a déjà était réservé pour cette date. Tu peux choisir une
            autre date.
          </div>
        )}
        {!isReserved && user && price && (
          <Reservation
            date={date}
            espaceId={espaceId}
            price={price}
            produitId={produitId}
            qtyDisponible={qtyDisponible}
            slotId={slotId}
            user={user}
          />
        )}

        {!user && (
          <div className="mt-12">
            <p className="mb-3">
              Nous t&apos;invitons à te connecter pour réserver
            </p>
            <SignUpOrIn href={href} />
          </div>
        )}
      </div>
    </TemplatePublic>
  );
};

export default getUser(PageEspaceProduitSlotDate);
