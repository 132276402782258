import { DataFieldProps, Form, SubmissionError } from '@innedit/formidable';
import { EventData, FeatureData } from '@innedit/innedit';
import {
  DocumentType,
  PriceType,
  ReservationType,
  TierType,
  UserType,
} from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import compact from 'lodash/compact';
import times from 'lodash/times';
import React, { FC, useEffect, useState } from 'react';

import displayCurrency from '../../utils/displayCurrency';

const getTierAmount = (ts: TierType[], q: number) => {
  const sortFunc = (a: TierType, b: TierType) => {
    if (a.upTo && b.upTo) {
      return a.upTo - b.upTo;
    }
    if (undefined === a.upTo && b.upTo) {
      return 1;
    }

    return -1;
  };

  const filterFunc = (fq: number) => (t: TierType) =>
    t.amount && (!t.upTo || t.upTo >= fq);

  if (0 === ts.length) {
    return undefined;
  }

  const [t] = [...ts].sort(sortFunc).filter(filterFunc(q));

  return t?.amount;
};

const ReservationForm: FC<{
  espaceId: string;
  date: string;
  price: DocumentType<PriceType>;
  produitId: string;
  qtyDisponible?: number;
  slotId: string;
  user: DocumentType<UserType>;
}> = ({
  espaceId,
  date,
  price,
  produitId,
  qtyDisponible = 0,
  slotId,
  user,
}) => {
  const priceData = price;

  const [qtyUser, setQtyUser] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [amount, setAmount] = useState<number>();

  useEffect(() => {
    let isMounted = true;
    let unsub: (() => void) | undefined;

    if (priceData) {
      const { tiers, scheme } = priceData;
      switch (scheme) {
        case 'per_unit': {
          setAmount(priceData.amount);
          break;
        }

        case 'graduated':
        case 'volume': {
          // Il faut rechercher le nombre de créneaux déjà réservé avec ce tarif
          const featureData = new FeatureData<ReservationType>({
            espaceId,
            collectionName: 'reservations',
          });

          unsub = featureData.watch(
            docs => {
              if (isMounted && tiers) {
                const oldQty = docs.reduce((acc, d) => acc + d.quantity, 0);
                setQtyUser(oldQty);

                const newAmount = getTierAmount(tiers, oldQty + 1);
                setAmount(newAmount);

                const oldTotal = times(oldQty).reduce(
                  (acc, i) => acc + (getTierAmount(tiers, i + 1) || 0),
                  0,
                );
                setTotal(oldTotal);
              }

              return true;
            },
            {
              wheres: {
                tarification: price.type,
                user: user.id,
              },
            },
          );
          break;
        }

        default:
          throw new Error('not implemented');
      }
    }

    return () => {
      isMounted = false;

      if (unsub) {
        unsub();
      }
    };
  }, [user.id, price.type, JSON.stringify(priceData)]);

  const handleOnSubmit = async (values: any) => {
    if (!priceData.isRecurring && !values.mode) {
      throw new SubmissionError({
        _error: 'Mode de paiement valide obligatoire',
      });
    }

    if (!qtyDisponible || qtyDisponible < values.quantity) {
      throw new SubmissionError({
        _error: 'Pas assez de places de disponible',
      });
    }

    // tout est ok, on peut réserver le créneau pour l'utilisateur
    const reservation = {
      amount,
      boutique: espaceId,
      expiredAt: date,
      paymentMode: values.mode,
      price: price.id,
      produit: produitId,
      quantity: 1,
      slot: slotId,
      user: user.id,
    };

    const eventData = new EventData({ espaceId });
    eventData.create({
      data: {
        object: reservation,
      },
      type: 'reservation.created',
    });

    await navigate(`/${espaceId}/${produitId}/${slotId}/${date}/reserve/`);
  };

  return (
    <>
      <ul className="mt-6 flex flex-col space-y-3">
        {qtyUser > 0 && (
          <li>
            Tu as déjà réservé {qtyUser} créneaux pour un total de {total}
            {priceData.currency ? displayCurrency(priceData.currency) : '€'}.
          </li>
        )}
        {['graduated', 'volume'].includes(priceData.scheme) &&
          0 === qtyUser && (
            <li>C&apos;est votre première réservation pour ce créneau</li>
          )}
        {amount && (
          <li>
            En réservant de créneau, il te reviendra à {amount}
            {priceData.currency ? displayCurrency(priceData.currency) : '€'}.
          </li>
        )}
      </ul>

      <Form
        className="mt-12"
        datas={compact([
          !priceData.isRecurring &&
            ({
              componentType: 'paiements/mode',
              label: 'Mode de paiement',
              name: 'mode',
              required: true,
            } as DataFieldProps),
        ])}
        footerProps={{ className: 'mt-6 flex justify-center' }}
        name="reservation"
        onSubmit={handleOnSubmit}
        params={{ espaceId, user }}
        removePristine
        submitProps={{
          label: 'Je réserve ce créneau',
        }}
      />
    </>
  );
};

export default ReservationForm;
