import React, { FC, SyntheticEvent, useState } from 'react';

import Button from '../../components/Button';
import Modal from '../../components/View/Modal';
import Inscription from '../Inscription';
import Login from '../Login';

const SignUpOrIn: FC<{ href: string }> = ({ href }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [type, setType] = useState<'sign-up' | 'sign-in'>();

  const handleCloseOnClick = () => {
    setIsOpen(false);
  };

  const handleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const newType = event.currentTarget.getAttribute('data-type');
    if (newType) {
      setType(newType as 'sign-up' | 'sign-in');
      setIsOpen(true);
    }
  };

  return (
    <div className="flex space-x-6">
      {isOpen && (
        <Modal
          closeOnClick={handleCloseOnClick}
          minWidth="420px"
          title={'sign-in' === type ? 'Identification' : 'Enregistrement'}
          width="auto"
        >
          {'sign-in' === type && <Login href={href} />}
          {'sign-up' === type && <Inscription />}
        </Modal>
      )}
      <Button
        color="neutral"
        data-type="sign-up"
        onClick={handleOnClick}
        variant="outline"
      >
        Créer un compte
      </Button>
      <Button data-type="sign-in" onClick={handleOnClick}>
        S&apos;identifier
      </Button>
    </div>
  );
};

export default SignUpOrIn;
